<template>
  <div class="collection">
    <panel-title icon="el-icon-folder-checked" title="收藏关注" />

    <panel-content>
      <div slot="filter" class="filter">
        <span class="active">产品</span>
        <span>服务商</span>
        <span>新闻咨询</span>
        <span>创业招商</span>
      </div>
      <el-empty
        slot="content"
        style="margin-top: 30px"
        description="暂无数据"
      ></el-empty>
    </panel-content>
  </div>
</template>

<script>
import PanelContent from "./PanelContent.vue";
import PanelTitle from "./PanelTitle.vue";
export default {
  components: { PanelTitle, PanelContent },
};
</script>

<style lang="scss" scoped>
.collection {
  .filter {
    span {
      cursor: pointer;
      display: inline-block;
      padding-right: 20px;
      margin-right: 20px;
      border-right: 1px solid #ccc;

      font-size: 14px;

      line-height: 17px;

      &:hover,
      &:active,
      &.active {
        color: red;
      }
    }

    span:last-child {
      border-right: none;
    }
  }
}
</style>